import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

const routes = [

    {
        //基本格式
        path: "/",
        name: "/",
        component: () => import( '@/views/home.vue'),
        meta: { title: '首页' }

    },
    {
        //规则协议页面
        path: "/rules",
        name: "/rules",
        component: () => import( '@/views/rules.vue'),
        meta: { title: '规范' }

    }

]

//路由对象
const router = createRouter({

    history: createWebHistory(process.env.BASE_URL),

    routes //上面的路由数组

})

//导出路由对象，在main.js中引用
export default router